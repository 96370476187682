import React from "react";
import { Button } from "reactstrap";
import plan from "../../assets/phasenext.pdf"
import phase2 from "../../assets/phase2.png"
const PhaseTwo = () => {

  const handleDownload = (pdfPath) => {
    // Create a link element
    const link = document.createElement("a");
    link.href = pdfPath;
    link.download = "phase2.pdf";
    link.target = "_blank";

    // Append the link to the document
    document.body.appendChild(link);

    // Trigger a click on the link to start the download
    link.click();

    // Remove the link from the document after the download
    document.body.removeChild(link);
  };
  return (
    <>
      <section className="svg-plan px-5">
        <div class="card mt-3 mb-3" style={{ width: "auto" }}>
          <center>
            <div class="card-body">
              <span>Phase 2</span>
              <p class="card-text">(Comming Soon)</p>
              <Button
                color="primary"
                onClick={() => handleDownload(plan)}
              >
                Download Pdf
              </Button>
            </div>
          </center>
        </div>
      </section>
      
      <div className="d-flex justify-content-center">
      <img className="w-75" src={phase2} alt="phase2" />
      </div>
    </>
  );
};

export default PhaseTwo;
